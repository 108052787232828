<template>
  <div>
    <q-btn-group outline :style="{ 'float':'right' }">
      <c-update-btn 
        name="updateBtn"
        :data="updateBtnData"
        :btnEditable="btnEditable"
        :flagCondition="flagCondition"
        @back="back"
      />
      <c-btn
        v-show="targetData.processAssessStepCd === 'RS00000005'"
        :label="'업체 [' + targetData.assessVendorName + '] 출력'"
        icon="print"
        @btnClicked="riskPrint" />
      <c-btn 
        v-if="completeEditable && !disabled"
        :isSubmit="isComplete"
        :url="completeUrl"
        :param="{
          riskAssessmentVendorId: this.targetData.riskAssessmentVendorId,
          meetList: this.actionData.meetList,
          scenarioList: this.actionData.scenarioList
        }"
        mappingType="PUT"
        :label="'업체 [' + targetData.assessVendorName + '] 평가완료'" 
        icon="check"
        @beforeAction="completeAction"
        @btnCallback="completeActionCallback" />
    </q-btn-group>
    <c-tab
      type="vertical"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :height="height"
          :assessPlan.sync="assessPlan"
          :actionData.sync="actionData"
          :targetData.sync="targetData"
          :planUpdateBtnData.sync="planUpdateBtnData"
          :updateBtnData.sync="updateBtnData"
          :param.sync="param"
          @research="research"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'risk-assess-action-detail',
  props: {
    targetData: {
      type: Object,
      default: () => ({
        riskAssessmentVendorId: '',
        riskAssessmentPlanId: '',
        assessVendorId: '',
        assessVendorName: '',
        processAssessStepCd: '',
        processAssessStepName: '',
      }),
    },
    assessPlan: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        plantCd: '',
        assessTypeCd: '',
        assessmentName: '',
        assessmentStartDate: '',
        assessmentEndDate: '',
        assessmentYear: '',
        stepCd: '',
        assessmentManageUserId: '',
        assessmentManageVendorCd: '',
        ramMatrixId: null,
        reviewDate: '',
        approvalUserId: '',
        approvalDate: '',
        sopMapId: '',
        remark: '',
        reviewUserId: ''
      }),
    },
    param: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        stepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    planUpdateBtnData: {
      title: '계획',
      flag: false,
      research: '',
      planResearch: '',
    },
  },
  data() {
    return {
      tab: 'meeting',
      actionData: {
        meetList: [],
        scenarioList: [],
      },
      updateBtnData: {
        title: '평가',
        flag: false,
        research: '',
      },
      completeUrl: '',
      isComplete: false,
      isApproval: false,
      getUrl: '',
      listUrl: '',
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.param.stepCd === 'RAS000010' || this.targetData.processAssessStepCd === 'RS00000005' || Boolean(this.assessPlan.sysApprovalRequestId)
            && !this.updateBtnData.flag
    },
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 180;
      return numHeight + 'px';
    },
    tabItems() {
      return [
        { name: 'meeting', icon: 'foundation', label: '회의', component: () => import(`${'./riskAssessMeeting.vue'}`) },
        { name: 'scenario', icon: 'account_tree', label: '실시', component: () => import(`${'./riskAssessScenario.vue'}`) },
      ]
    },
    btnEditable() {
      return this.editable && this.param.stepCd === 'RAS000005' && this.targetData.processAssessStepCd === 'RS00000005'
        && Boolean(!this.assessPlan.sysApprovalRequestId)
    },
    flagCondition() {
      return this.param.stepCd !== 'RAS000005'
    },
    editable() {
      return this.$route.meta.editable && 
      (this.assessPlan.approvalUserId === this.$store.getters.user.userId
        || this.assessPlan.reviewUserId === this.$store.getters.user.userId
        || this.assessPlan.regUserId === this.$store.getters.user.userId)
    },
    completeEditable() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return this.$route.meta.editable && 
          (this.$store.getters.user.deptCd === this.targetData.assessVendorId
           || this.assessPlan.approvalUserId === this.$store.getters.user.userId
           || this.assessPlan.reviewUserId === this.$store.getters.user.userId
           || this.assessPlan.regUserId === this.$store.getters.user.userId)
      } else {
        return this.$route.meta.editable && 
          (this.$store.getters.user.selfVendorCd === this.targetData.assessVendorId
           || this.assessPlan.approvalUserId === this.$store.getters.user.userId
           || this.assessPlan.reviewUserId === this.$store.getters.user.userId
           || this.assessPlan.regUserId === this.$store.getters.user.userId)
      }
    },
  },
  watch: {
    'updateBtnData.flag'() {
      this.$emit('approveChange', this.updateBtnData.flag)
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.printUrl = selectConfig.saas.status.print.url;
      this.completeUrl = transactionConfig.saas.targetVendor.complete.url;
      this.listUrl = selectConfig.saas.action.list.url;
      this.getUrl = selectConfig.saas.targetVendor.get.url;
      // code setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        riskAssessmentVendorId: this.targetData.riskAssessmentVendorId,
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
        assessVendorId: this.targetData.assessVendorId,
      }
      this.$http.request((_result) => {
        this.actionData = this.$_.clone(_result.data);

        if (_result.data.meetList && _result.data.meetList.length > 0) {
          this.$_.forEach(_result.data.meetList, _item => {
            _item.meetingTime = _item.meetingStartTime && _item.meetingEndTime ?
            [_item.meetingStartTime, _item.meetingEndTime] : []
          })

          _result.data.meetList[_result.data.meetList.length-1].expanded = true;
        }
      },);
    },
    research() {
      this.$http.url = this.$format(this.getUrl, this.targetData.riskAssessmentVendorId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.targetData, 'processAssessStepCd', _result.data.processAssessStepCd)
        this.$set(this.targetData, 'processAssessStepName', _result.data.processAssessStepName)
      },);
      this.$emit('research', this.targetData.riskAssessmentVendorId)
    },
    completeAction() {
      let isProgress = true;

      if (!this.actionData.scenarioList) {
        isProgress = false
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '평가대상이 없습니다. 실시탭에서 평가대상을 추가하세요.',
          type: 'warning', // success / info / warning / error
        });
        return false;
      }
      this.$_.forEach(this.actionData.meetList, _item => {
        if (!_item.riskAssessmentMeetingId) {
          isProgress = false
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '회의탭에서 작성중인 회의를 입력 후 저장하세요.',
            type: 'warning', // success / info / warning / error
          });
          return false;
        }
      }) 
      this.$_.forEach(this.actionData.scenarioList, scenario => {
        if (!scenario.processName || !scenario.sopName || !scenario.riskHazardClassCd
        || !scenario.assessDate || !scenario.assessUserName) {
          isProgress = false
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '실시탭에서 평가대상의 필수 값을 입력하세요.',
            type: 'warning', // success / info / warning / error
          });
          return false;
        } 
      });

      if (isProgress) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '완료하시겠습니까?(저장과 함께 완료됩니다.)',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.targetData.chgUserId = this.$store.getters.user.userId

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeActionCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.targetData, 'processAssessStepCd', 'RS00000005')
      this.$emit('research', this.targetData.riskAssessmentVendorId)
    },
    riskPrint() {
      let thisVue = this;
      this.$http.url = this.printUrl
      this.$http.type = 'GET';
      this.$http.param = {
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
        assessVendorId: this.targetData.assessVendorId,
      }
      this.$http.request(
        _result => {
          let fileOrgNm = '위험성평가결과_' + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getList();
    },
  }
}
</script>